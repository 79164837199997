import { Chip, Grid, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import OrderCompanyLogo from '../../../../Orders/elements/OrderCompanyLogo';
import OrderSourceLogo from '../../../../Orders/elements/OrderSourceLogo';
import OrderStatusTypography from '../../../../Orders/elements/OrderStatusTypography';
import {
  capitalizeString,
  extractFirstAndLastName,
  formatDateWithHour,
  numberToRealWithPeriod,
} from '../../../../../helpers/formatData';

const useStyles = makeStyles((theme) => ({
  typographyCursor: {
    cursor: 'pointer',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowSelected: {
    cursor: 'pointer',
    backgroundColor: theme.palette.grey[300],
    '&:hover': {
      backgroundColor: `${theme.palette.grey[300]} !important`,
    },
  },
}));

function OrdersTableBody({
  ordersList,
  selectedOrders,
  setSelectedOrders,
  page,
  handleChangePage,
  loadingOrdersList,
  handleOnlyOpenDetail,
}) {
  const classes = useStyles();

  const getColorSelected = (order) => {
    if (selectedOrders.find((selectedOrder) => selectedOrder.orderId === order.orderId)) {
      return classes.tableRowSelected;
    }
    return classes.tableRow;
  };

  const handleSelectUnselectOrder = useCallback(
    (order) => {
      const getSelectedOrder = selectedOrders.find(
        (selectedOrder) => selectedOrder.orderId === order.orderId,
      );

      if (getSelectedOrder) {
        const alreadySelectedOrders = selectedOrders.filter(
          (selectedOrder) => selectedOrder.orderId !== order.orderId,
        );

        setSelectedOrders(alreadySelectedOrders);
      } else {
        setSelectedOrders([...selectedOrders, order]);
      }
    },
    [selectedOrders],
  );

  const getEntryPayment = (payments) => {
    const getFinalValue = (payment) => {
      const value = payment.value || 0;
      const decreaseValue = payment.decreaseValue || 0;
      const additionalValue = payment.additionalValue || 0;

      return value - decreaseValue + additionalValue;
    };

    const receiptsPending = payments.filter(
      ({ type, status }) => type === 'Recebimento' && status !== 'Pago',
    );

    let totalValue = receiptsPending.reduce((total, receipt) => total + getFinalValue(receipt), 0);
    let updatedStatus = 'Não pago';

    if (receiptsPending.length === 0) {
      const receiptsPaid = payments.filter(
        ({ type, status }) => type === 'Recebimento' && status === 'Pago',
      );

      if (receiptsPaid.length > 0) {
        totalValue = receiptsPaid.reduce((total, receipt) => total + getFinalValue(receipt), 0);
        updatedStatus = 'Pago';
      }
    }

    const color = updatedStatus === 'Pago' ? '#00c600' : '#ff2222';

    return {
      totalValue,
      status: updatedStatus,
      color,
    };
  };

  return (
    <TableBody>
      {ordersList.length > 0 &&
        ordersList.map((order) => {
          const entryPayment = getEntryPayment(order.payments);

          return (
            <TableRow
              key={order.orderId}
              className={getColorSelected(order)}
              onClick={() => handleSelectUnselectOrder(order)}
            >
              <TableCell align="center">
                {handleOnlyOpenDetail ? (
                  <Chip
                    onClick={(event) => {
                      event.stopPropagation();
                      handleOnlyOpenDetail(order);
                    }}
                    label={order.orderId}
                    color="primary"
                    size="small"
                  />
                ) : (
                  <Typography>{order.orderId}</Typography>
                )}
              </TableCell>
              <TableCell align="center">
                <OrderCompanyLogo orderCompany={order.company} />
              </TableCell>
              <TableCell align="center">
                <OrderSourceLogo orderSource={order.source} />
              </TableCell>
              <TableCell align="center">
                <Grid container direction="column">
                  <Typography>
                    R$ {numberToRealWithPeriod(entryPayment.totalValue || order.orderTotal)}
                  </Typography>
                  <Typography variant="caption" style={{ color: entryPayment.color }}>
                    {entryPayment.status}
                  </Typography>
                </Grid>
              </TableCell>
              <TableCell align="center">{formatDateWithHour(order.createdAt)}</TableCell>
              <TableCell align="center">
                <Grid container direction="column">
                  <Grid item>
                    <OrderStatusTypography orderStatus={order.status} onlyStatusName />
                  </Grid>
                  {order.seller?.name ? (
                    <Grid item>
                      <Typography variant="caption">
                        {capitalizeString(extractFirstAndLastName(order.seller.name))}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              </TableCell>
            </TableRow>
          );
        })}

      {page !== 1 && ordersList.length === 0 && (
        <TableRow>
          <TableCell colSpan={7}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography
                  onClick={(event) => handleChangePage(event, 1)}
                  className={classes.typographyCursor}
                >
                  Nenhuma pedido nesta página, clique aqui para voltar a página.
                </Typography>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      )}

      {page === 1 && ordersList.length === 0 && (
        <TableRow>
          <TableCell colSpan={7}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography>
                  {loadingOrdersList ? 'Carregando...' : 'Nenhum pedido para exibir'}
                </Typography>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

export default OrdersTableBody;
