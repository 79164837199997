import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import filterOrderSourceInitial from '../../../constant/filterOrderSourceInitial';
import FilterSelectOptions from '../../Common/FilterSelectOptions';
import CustomDatePicker from '../../CustomDatePicker';
import filterOrderStatusInitial from '../../../constant/filterOrderStatusInitial';
import UserAutocomplete from '../../Common/UserAutocomplete';
import filterProductOptionsInitial from '../../../constant/filterUserOptionsInitial';
import CompanySelectOptions from '../../Common/CompanySelectOptions';
import UserCityAutocomplete from '../../Common/UserCityAutocomplete';

const useStyles = makeStyles({
  select: {
    minWidth: 200,
  },
});

function OrdersReports() {
  const classes = useStyles();

  const [filtersOptions, setFiltersOptions] = useState(filterProductOptionsInitial);
  const [filterOrderSource, setFilterOrderSource] = useState(filterOrderSourceInitial);
  const [filterOrderStatus, setFilterOrderStatus] = useState(filterOrderStatusInitial);
  const [filterOrderCompany, setFilterOrderCompany] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [user, setUser] = useState(null);
  const [seller, setSeller] = useState(null);

  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month').startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().subtract(1, 'month').endOf('month'));
  const [dateBy, setDateBy] = useState('createdAt');

  const [onlyPaid, setOnlyPaid] = useState(false);
  const [onlyWithInvoice, setOnlyWithInvoice] = useState(false);
  const [onlyCompanyStock, setOnlyCompanyStock] = useState(false);
  const [loading, setLoading] = useState(false);

  const getReports = async (reportType) => {
    try {
      setLoading(true);

      const response = await Axios.post('/reports/orders/', {
        reportType,
        filtersOptions,
        filterOrderSource,
        filterOrderCompany,
        filterOrderStatus,
        userId: user?.userId,
        sellerId: seller?._id,
        city: selectedCity?.city,
        state: selectedCity?.state,
        startDate: dayjs(startDate).startOf('day'),
        endDate: dayjs(endDate).endOf('day'),
        dateBy,
        onlyPaid,
        onlyWithInvoice,
        onlyCompanyStock,
      });

      const { url } = response.data;
      window.open(url);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleChangeStartDate = (date) => setStartDate(date);
  const handleChangeEndDate = (date) => setEndDate(date);

  const handleSelectedCustomer = (event, newValue) => {
    if (newValue) {
      setOnlyCompanyStock(true);
      setUser(newValue);
    } else {
      setOnlyCompanyStock(false);
      setUser(null);
    }
  };

  const handleSelectedSeller = (event, newValue) => {
    if (newValue) {
      setSeller(newValue);
    } else {
      setSeller(null);
    }
  };

  const handleOnChangeCity = (event, newValue) => {
    if (newValue) {
      setSelectedCity(newValue);
    } else {
      setSelectedCity(null);
    }
  };

  return (
    <Box marginTop={2}>
      <Paper>
        <Box padding={2}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <CompanySelectOptions
                        inputLabel="Empresa"
                        filtersOptions={filterOrderCompany}
                        setFiltersOptions={setFilterOrderCompany}
                        hideButton
                      />
                    </Grid>
                    <Grid item>
                      <FilterSelectOptions
                        inputLabel="Origem do pedido"
                        filtersOptions={filterOrderSource}
                        setFiltersOptions={setFilterOrderSource}
                        hideButton
                      />
                    </Grid>
                    <Grid item>
                      <FilterSelectOptions
                        inputLabel="Situação do pedido"
                        filtersOptions={filterOrderStatus}
                        setFiltersOptions={setFilterOrderStatus}
                        hideButton
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            value={dateBy === 'confirmation'}
                            onChange={() =>
                              setDateBy(dateBy === 'confirmation' ? 'createdAt' : 'confirmation')
                            }
                            checked={dateBy === 'confirmation'}
                            color="secondary"
                          />
                        }
                        label="Considerar data de confirmação"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <UserAutocomplete
                        handleOnChange={handleSelectedCustomer}
                        selectedUser={user}
                        label="Cliente"
                        variant="filled"
                        className={classes.select}
                      />
                    </Grid>
                    <Grid item>
                      <FilterSelectOptions
                        inputLabel="Filtrar clientes com"
                        multiple={false}
                        filtersOptions={filtersOptions}
                        setFiltersOptions={setFiltersOptions}
                        hideButton
                      />
                    </Grid>
                    <Grid item>
                      <UserAutocomplete
                        handleOnChange={handleSelectedSeller}
                        selectedUser={seller}
                        label="Vendedor"
                        roles={['staff']}
                        variant="filled"
                        className={classes.select}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            value={dateBy === 'invoice.emissionDate'}
                            onChange={() =>
                              setDateBy(
                                dateBy === 'invoice.emissionDate'
                                  ? 'createdAt'
                                  : 'invoice.emissionDate',
                              )
                            }
                            checked={dateBy === 'invoice.emissionDate'}
                            color="secondary"
                          />
                        }
                        label="Considerar data do faturamento"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Box className={classes.select}>
                        <UserCityAutocomplete
                          handleOnChange={handleOnChangeCity}
                          selectedCity={selectedCity}
                          variant="filled"
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={200}>
                        <CustomDatePicker
                          size="small"
                          label="Data inicial"
                          format="DD/MM/YYYY"
                          value={startDate}
                          onChange={handleChangeStartDate}
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box width={200}>
                        <CustomDatePicker
                          size="small"
                          label="Data final"
                          format="DD/MM/YYYY"
                          value={endDate}
                          onChange={handleChangeEndDate}
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            value={onlyWithInvoice}
                            onChange={() => setOnlyWithInvoice(!onlyWithInvoice)}
                            checked={onlyWithInvoice}
                            color="secondary"
                          />
                        }
                        label="Buscar somente faturados"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={3}>
                {loading ? (
                  <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                      <Grid item>
                        <Typography>Aguarde. Essa ação pode levar alguns instantes.</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            getReports('csv');
                          }}
                        >
                          Baixar CSV
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            getReports('pdf');
                          }}
                        >
                          Baixar PDF
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        value={dateBy === 'payments.payDate'}
                        onChange={() =>
                          setDateBy(
                            dateBy === 'payments.payDate' ? 'createdAt' : 'payments.payDate',
                          )
                        }
                        checked={dateBy === 'payments.payDate'}
                        color="secondary"
                      />
                    }
                    label="Considerar data do pagamento"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        value={onlyPaid}
                        onChange={() => setOnlyPaid(!onlyPaid)}
                        checked={onlyPaid}
                        color="secondary"
                      />
                    }
                    label="Somente pedidos pagos"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        value={onlyCompanyStock}
                        onChange={() => setOnlyCompanyStock(!onlyCompanyStock)}
                        checked={onlyCompanyStock}
                        color="secondary"
                      />
                    }
                    label="Somente empresa"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
}

export default OrdersReports;
